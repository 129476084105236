import axios from "axios";
import { authService } from "./services";
import { useToast } from "vue-toastification";

const toast = useToast();

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const keycloak = await authService.refreshToken();
  if (keycloak) config.headers.Authorization = `Bearer ${keycloak.token}`;

  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response) {
      if (
        !!Object.getOwnPropertyDescriptor(error.config, "errorHandle") &&
        error.config.errorHandle === false
      ) {
        return Promise.reject(error);
      } else {
        toast.error(error.response.data.errors[0].message);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
