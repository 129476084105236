import apiClient from "@/api";

export const userService = {
  getCompleteUser,
  getUsers,
  searchUsers,
  getUserById,
  getUserLinkedAccountsById,
};

async function getCompleteUser() {
  return (await apiClient.get(`/users/me/complete`)).data;
}

async function getUsers(
  limit = 20,
  offset = 0,
  query = "",
  sort = "username",
  order = "asc"
) {
  return await apiClient.get(
    `/users?limit=${limit}&offset=${offset}&search=${query}&sort_field=${sort}&sort_order=${order}`
  );
}

async function searchUsers(
  limit = 20,
  offset = 0,
  query = "",
  sort = "username",
  order = "asc"
) {
  return await apiClient.get(
    `/users/search?limit=${limit}&offset=${offset}&search=${query}&sort_field=${sort}&sort_order=${order}`
  );
}

async function getUserById(id) {
  return (await apiClient.get(`/users/${id}`)).data;
}

async function getUserLinkedAccountsById(id) {
  return (await apiClient.get(`/users/${id}/linked_accounts`)).data;
}
