import apiClient from "@/api";

export const cronService = {
  getCronStatus,
  getPSNQueue,
  getPSNQueueDetail,
  deletePSNQueueItem,
  getXboxQueue,
  getXboxQueueDetail,
  deleteXboxQueueItem,
  getSteamQueue,
  getSteamQueueDetail,
  deleteSteamQueueItem,
  getGOGQueue,
  getGOGQueueDetail,
  deleteGOGQueueItem,
  getRetroQueue,
  getRetroQueueDetail,
  deleteRetroQueueItem,
  getFFXIVQueue,
  getGameServiceQueue,
  executeCron,
};

async function getCronStatus() {
  return (await apiClient.get(`/crons/status`)).data;
}

async function getPSNQueue() {
  return (await apiClient.get(`/crons/psn_queue`)).data;
}

async function getPSNQueueDetail(queue, limit = 20, offset = 0) {
  return apiClient.get(
    `/psn_sync/queue/${queue}?limit=${limit}&offset=${offset}`
  );
}

async function deletePSNQueueItem(id) {
  return apiClient.delete(`/psn_sync/${id}`);
}

async function getXboxQueue() {
  return (await apiClient.get(`/crons/xbox_queue`)).data;
}

async function getXboxQueueDetail(queue, limit = 20, offset = 0) {
  return apiClient.get(
    `/xbox_sync/queue/${queue}?limit=${limit}&offset=${offset}`
  );
}

async function deleteXboxQueueItem(id) {
  return apiClient.delete(`/xbox_sync/${id}`);
}

async function getSteamQueue() {
  return (await apiClient.get(`/crons/steam_queue`)).data;
}

async function getSteamQueueDetail(queue, limit = 20, offset = 0) {
  return apiClient.get(
    `/steam_sync/queue/${queue}?limit=${limit}&offset=${offset}`
  );
}

async function deleteSteamQueueItem(id) {
  return apiClient.delete(`/steam_sync/${id}`);
}

async function getGOGQueue() {
  return (await apiClient.get(`/crons/gog_queue`)).data;
}

async function getGOGQueueDetail(queue, limit = 20, offset = 0) {
  return apiClient.get(
    `/gog_sync/queue/${queue}?limit=${limit}&offset=${offset}`
  );
}

async function deleteGOGQueueItem(id) {
  return apiClient.delete(`/gog_sync/${id}`);
}

async function getRetroQueue() {
  return (await apiClient.get(`/crons/retro_achievement_queue`)).data;
}

async function getRetroQueueDetail(queue, limit = 20, offset = 0) {
  return apiClient.get(
    `/retro_achievement_sync/queue/${queue}?limit=${limit}&offset=${offset}`
  );
}

async function deleteRetroQueueItem(id) {
  return apiClient.delete(`/retro_achievement_sync/${id}`);
}

async function getFFXIVQueue() {
  return (await apiClient.get(`/crons/ffxiv_queue`)).data;
}

async function getGameServiceQueue() {
  return (await apiClient.get(`/crons/game_service_queue`)).data;
}

async function executeCron(cron) {
  return (await apiClient.post(`/crons/execute/${cron}`)).data;
}
