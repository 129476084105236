<template v-if="isLoaded">
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    store.dispatch("init");

    return {
      isContributor: computed(() => store.getters["auth/isContributor"]),
      isQuestforger: computed(() => store.getters["auth/isQuestforger"]),
      changePage: () => store.dispatch("changePage"),
    };
  },

  components: {
    Simple: defineAsyncComponent(() => import("@/layouts/Simple.vue")),
    Default: defineAsyncComponent(() => import("@/layouts/Default.vue")),
  },

  computed: {
    layout() {
      return this.isContributor || this.isQuestforger ? "default" : "simple";
    },
  },

  watch: {
    $route(to, from) {
      this.changePage({ current: to, previous: from });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-top: 65px;
  padding-left: 265px;
  transition: padding-left 0.3s ease;

  &.menu-closed {
    padding-left: 0;
  }

  & > .container-fluid {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
