import Keycloak from "keycloak-js";
import apiClient from "@/api";
import store from "@/store";

export const authService = {
  login,
  logout,
  autoLogin,
  refreshToken,
};

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
};

const keycloak = new Keycloak(initOptions);

async function login() {
  await asyncKeycloakkWrapper("login-required");
}

async function asyncKeycloakkWrapper(type, silent) {
  const silentCheckSsoRedirectUri = silent
    ? {
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      }
    : null;

  return new Promise((resolve, reject) => {
    keycloak
      .init(type ? { ...silentCheckSsoRedirectUri, onLoad: type } : "")
      .then((auth) => {
        auth ? resolve(keycloak) : resolve(false);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function refreshToken() {
  return new Promise((resolve, reject) => {
    keycloak
      .updateToken(60)
      .then((refreshed) => {
        if (refreshed) {
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${keycloak.token}`;
          resolve(keycloak);
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        store.dispatch("auth/logout");
        reject();
      });
  });
}

async function autoLogin() {
  try {
    const auth = await asyncKeycloakkWrapper("check-sso", true);
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    return auth;
  } catch (err) {
    return err;
  }
}

async function logout() {
  keycloak.init().then(() => keycloak.logout());
}
