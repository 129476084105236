import apiClient from "@/api";

export const metricService = {
  getMetricByDate,
};

async function getMetricByDate(date_from, date_to, type) {
  return (
    await apiClient.get("/site_metrics", {
      params: { type, date_from, date_to },
    })
  ).data;
}