import apiClient from "@/api";

export const reportService = {
  getReports,
  acceptReport,
  deleteReport,
};

async function getReports(limit = 30, offset = 0) {
  return await apiClient.get(
    `/reports?limit=${limit}&offset=${offset}&accepted=false&archived=false`
  );
}

async function acceptReport(reportId) {
  return await apiClient.put(`/reports/${reportId}/accept`);
}

async function deleteReport(reportId) {
  return await apiClient.delete(`/reports/${reportId}`);
}
