import { createRouter, createWebHistory } from "vue-router";
import {
  ifAdmin,
  ifModerator,
  ifContributor,
  ifScholar,
  ifQuestforger,
  ifContributorOrQuestForger,
} from "@/helpers/authGuard.js";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dasbhoard" */ "../views/Dashboard.vue"),
    beforeEnter: ifContributorOrQuestForger,
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue"),
    beforeEnter: ifScholar,
  },
  {
    path: "/monitoring/metrics",
    name: "Metrics",
    component: () =>
      import(
        /* webpackChunkName: "metrics" */ "../views/monitoring/Metrics.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Monitoring" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/monitoring/scheduled-tasks",
    name: "Scheduled Tasks",
    component: () =>
      import(
        /* webpackChunkName: "scheduledTasks" */ "../views/monitoring/ScheduledTasks.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Monitoring" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/monitoring/queue",
    name: "Queue",
    component: () =>
      import(/* webpackChunkName: "queue" */ "../views/monitoring/Queue.vue"),
    meta: {
      breadcrumb: [{ name: "Monitoring" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/monitoring/queue/:type/:name",
    name: "Queue List",
    component: () =>
      import(
        /* webpackChunkName: "queue" */ "../views/monitoring/QueueList.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Monitoring" },
        { name: "Queue", url: "/monitoring/queue" },
      ],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/monitoring/webhooks",
    name: "Webhooks",
    component: () =>
      import(
        /* webpackChunkName: "webhooks" */ "../views/monitoring/Webhooks.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Monitoring" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/data/sync-issues",
    name: "Sync Issues",
    component: () =>
      import(
        /* webpackChunkName: "syncIssues" */ "../views/data/SyncIssues.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Data" }],
    },
    beforeEnter: ifContributor,
  },
  {
    path: "/data/deletions",
    name: "Deletions",
    component: () =>
      import(/* webpackChunkName: "deletions" */ "../views/data/Deletions.vue"),
    meta: {
      breadcrumb: [{ name: "Data" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/data/game-links",
    name: "Game Links",
    component: () =>
      import(/* webpackChunkName: "gameLinks" */ "../views/data/GameLinks.vue"),
    meta: {
      breadcrumb: [{ name: "Data" }],
    },
    beforeEnter: ifContributor,
  },
  {
    path: "/data/service-games",
    name: "Service Games",
    component: () =>
      import(
        /* webpackChunkName: "serviceGames" */ "../views/data/ServiceGames.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Data" }],
    },
    beforeEnter: ifScholar,
  },
  {
    path: "/data/hltb-data",
    name: "HLTB Data",
    component: () =>
      import(/* webpackChunkName: "hltbData" */ "../views/data/HltbData.vue"),
    meta: {
      breadcrumb: [{ name: "Data" }],
    },
    beforeEnter: ifScholar,
  },
  {
    path: "/data/service-games/:id",
    name: "Service Game",
    component: () =>
      import(
        /* webpackChunkName: "serviceGame" */ "../views/data/ServiceGame.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Data" },
        { name: "Service Games", url: "/data/service-games" },
      ],
    },
    beforeEnter: ifScholar,
  },
  {
    path: "/data/service-games/psn/:id",
    name: "PSN Game",
    component: () =>
      import(
        /* webpackChunkName: "serviceGame" */ "../views/data/ServiceGame.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Data" },
        { name: "Service Games", url: "/data/service-games" },
        { name: "PSN" },
      ],
    },
    beforeEnter: ifScholar,
  },
  {
    path: "/users/patreon",
    name: "Patreon",
    component: () =>
      import(/* webpackChunkName: "patreon" */ "../views/users/Patreon.vue"),
    meta: {
      breadcrumb: [{ name: "Users" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Users.vue"),
    beforeEnter: ifAdmin,
  },
  {
    path: "/users/:id",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/users/User.vue"),
    meta: {
      breadcrumb: [{ name: "Users", url: "/users" }],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/content/challenges",
    name: "Challenges",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/Challenges.vue"
      ),
    meta: {
      breadcrumb: [{ name: "Content" }],
    },
    beforeEnter: ifQuestforger || ifScholar,
  },
  {
    path: "/content/challenges/events",
    name: "Events",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/challenges/Events.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Content" },
        { name: "Challenges", url: "/content/challenges" },
      ],
    },
    beforeEnter: ifQuestforger,
  },
  {
    path: "/content/challenges/events/:id",
    name: "Challenge Event",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/challenges/ChallengeEvent.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Content" },
        { name: "Challenges", url: "/content/challenges" },
        { name: "Events", url: "/content/challenges/events" },
      ],
    },
    beforeEnter: ifQuestforger,
  },
  {
    path: "/content/challenges/events/new",
    name: "New Challenge Event",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/challenges/ChallengeEvent.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Content" },
        { name: "Challenges", url: "/content/challenges" },
        { name: "Events", url: "/content/challenges/events" },
      ],
    },
    beforeEnter: ifAdmin,
  },
  {
    path: "/content/challenges/:id",
    name: "Challenge",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/challenges/Challenge.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Content" },
        { name: "Challenges", url: "/content/challenges" },
      ],
    },
    beforeEnter: ifQuestforger,
  },
  {
    path: "/content/challenges/new",
    name: "New Challenge",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "../views/content/challenges/Challenge.vue"
      ),
    meta: {
      breadcrumb: [
        { name: "Content" },
        { name: "Challenges", url: "/content/challenges" },
      ],
    },
    beforeEnter: ifQuestforger,
  },
  {
    path: "/content/reports",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/content/Reports.vue"),
    meta: {
      breadcrumb: [{ name: "Content" }],
    },
    beforeEnter: ifModerator,
  },
  {
    path: "/403",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/Forbidden.vue"),
    meta: {
      layout: "blank",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
