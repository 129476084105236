import store from "@/store/index.js";

export const ifAdmin = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      store.getters["auth/isAdmin"]
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};

export const ifModerator = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      store.getters["auth/isModerator"]
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};

export const ifContributor = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      store.getters["auth/isContributor"]
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};


export const ifScholar = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      store.getters["auth/isScholar"]
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};

export const ifQuestforger = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      (store.getters["auth/isQuestforger"] || store.getters["auth/isScholar"])
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};

export const ifContributorOrQuestForger = (to, from, next) => {
  function proceed() {
    if (
      store.getters["auth/isAuthenticated"] &&
      (store.getters["auth/isQuestforger"] || store.getters["auth/isContributor"])
    ) {
      next();
    } else {
      next("/403");
    }
  }

  checkStoreState(proceed);
};

function checkStoreState(proceed) {
  if (!store.state.isLoaded) {
    store.watch(
      (state) => state.isLoaded,
      (value) => {
        if (value === true) proceed();
      }
    );
  } else {
    proceed();
  }
}
