export default {
  namespaced: true,

  state: {
    modal: [],
    lastModalSize: "large",
  },

  mutations: {
    OPEN_MODAL(state, modal) {
      state.modal.unshift(modal);
    },

    CLOSE_MODAL(state) {
      state.lastModalSize = state.modal[0].size;
      state.modal.shift();
    },
  },

  actions: {
    openModal({ commit, dispatch }, payload) {
      const modal = {
        ...payload,
        size: payload.size ? payload.size : "large",
        canClickOutside:
          payload.canClickOutside === false ? payload.canClickOutside : true,
        showCloseButton:
          payload.showCloseButton === false ? payload.showCloseButton : true,
      };

      commit("OPEN_MODAL", modal);
      dispatch("lockScroll");
    },

    closeModal({ state, commit, dispatch }) {
      commit("CLOSE_MODAL");
      if (state.modal.length === 0) dispatch("unlockScroll");
    },

    lockScroll() {
      document.documentElement.classList.add("scroll-lock");
    },

    unlockScroll() {
      document.documentElement.classList.remove("scroll-lock");
    },
  },

  getters: {
    activeModal(state) {
      return state.modal[0] || null;
    },

    modalSize(state) {
      return state.modal.length ? state.modal[0].size : state.lastModalSize;
    },
  },
};
