import apiClient from "@/api";

export const gameService = {
  getPlatforms,
  getGameById,
  getExpandedGameById,
  searchForGames,
  getGameCategories,
};

async function getPlatforms() {
  return (await apiClient.get(`/platforms?sort_field=order&sort_order=desc`))
    .data;
}

async function getGameById(id) {
  const game = await apiClient.get(`/games/${id}`);
  return game.data.length ? game.data[0] : null;
}

async function getExpandedGameById(id, expand = "limited") {
  const game = await apiClient.get(`/games/${id}?expand=${expand}`);
  return game.data.length ? game.data[0] : null;
}

async function searchForGames(query, filters) {
  return await apiClient.get(`/games`, {
    params: { ...filters, query: query, limit: 50 },
  });
}

async function getGameCategories(gameId, categories = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]) {
  return (await apiClient.get(`/games/${gameId}/categories?category=${categories}`)).data;
}