import { createApp } from "vue";
import Toast from "vue-toastification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/base.scss";
import "vue-toastification/dist/index.css";

const options = {
  position: "bottom-right",
  timeout: 2000,
  maxToasts: 1,
};

createApp(App).use(store).use(router).use(Toast, options).mount("#app");
