import { authService, userService } from "@/services";

export default {
  namespaced: true,

  state: {
    token: null,
    id: null,
    refreshToken: null,
    exp: null,
    user: null,
  },

  getters: {
    isAuthenticated(state) {
      return !!(state.token && state.user && state.user.id);
    },

    isAdmin(state) {
      return state.token && state.user
        ? state.user.roles.includes("admin")
        : false;
    },

    isModerator(state) {
      return state.token && state.user
        ? state.user.roles.includes("backlogSupporter") ||
            state.user.roles.includes("admin")
        : false;
    },

    isContributor(state) {
      return state.token && state.user
        ? state.user.roles.includes("contributor") ||
            state.user.roles.includes("scholar") ||
            state.user.roles.includes("admin") ||
            state.user.roles.includes("backlogSupporter")
        : false;
    },

    isScholar(state) {
      return state.token && state.user
        ? state.user.roles.includes("scholar") ||
            state.user.roles.includes("admin") ||
            state.user.roles.includes("backlogSupporter")
        : false;
    },

    isQuestforger(state) {
      return state.token && state.user
        ? state.user.roles.includes("questforger") ||
            state.user.roles.includes("admin") ||
            state.user.roles.includes("backlogSupporter")
        : false;
    },
  },

  mutations: {
    AUTH_USER(state, userData) {
      state.token = userData.token;
      state.uuid = userData.id;
      state.refreshToken = userData.refreshToken;
      state.exp = userData.exp;
    },
    LOGIN_FAILURE(state) {
      state.token = null;
      state.id = null;
      state.refreshToken = null;
      state.exp = null;
    },
    CLEAR_AUTH_DATA(state) {
      state.token = null;
      state.id = null;
      state.refreshToken = null;
      state.exp = null;
    },
    SET_USER_DATA(state, user) {
      state.user = user;
      state.id = user.id;
    },
  },

  actions: {
    login: async ({ commit }, authData) => {
      try {
        await authService.login(authData);
      } catch (error) {
        commit("LOGIN_FAILURE");
      }
    },

    setAuthCredentials({ commit }, user) {
      if (user) {
        commit("AUTH_USER", {
          token: user.token,
          id: user.tokenParsed.sub,
          refreshToken: user.refreshToken,
          exp: user.tokenParsed.exp,
        });
      }
    },

    clearAuthCredentials({ commit }) {
      commit("CLEAR_AUTH_DATA");
    },

    autoLogin: async ({ dispatch }) => {
      try {
        const user = await authService.autoLogin();

        if (user) {
          dispatch("setAuthCredentials", user);
          await dispatch("getUserData");
        } else {
          dispatch("login");
        }
      } catch (error) {
        console.log(error);
      }
    },

    logout: async () => {
      authService.logout();
    },

    getUserData: async ({ commit }) => {
      const userInfo = await userService.getCompleteUser();
      commit("SET_USER_DATA", userInfo);
    },
  },
};
