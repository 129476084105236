import apiClient from "@/api";

export const challengeService = {
  getChallenges,
  getChallenge,
  getChallengeRewards,
  getChallengeEvents,
  getUnpublishedChallengeEvents,
  getChallengeEvent,
  createChallenge,
  createChallengeEvent,
  updateChallenge,
  updateChallengeEvent,
  deleteChallenge,
  deleteChallengeEvent,
};

async function getChallenges(
  limit = 50,
  offset = 0,
  sorting = "created_at",
  order = "desc",
  published = true,
  category = null,
  availability = null
) {
  return await apiClient.get(
    `/challenges?limit=${limit}&offset=${offset}&sort_field=${sorting}&sort_order=${order}&published=${published}${
      availability ? `&availability=${availability}` : ""
    }${category ? `&category=${category}` : ""}`
  );
}

async function getChallengeEvents(
  limit = 50,
  offset = 0,
  sorting = "created_at",
  order = "desc"
) {
  return await apiClient.get(
    `/challenge_events?limit=${limit}&offset=${offset}&sort_field=${sorting}&sort_order=${order}`
  );
}

async function getUnpublishedChallengeEvents(
  limit = 50,
  offset = 0,
  sorting = "created_at",
  order = "desc"
) {
  return await apiClient.get(
    `/challenge_events?limit=${limit}&offset=${offset}&sort_field=${sorting}&sort_order=${order}&published=false`
  );
}

async function getChallenge(challengeId) {
  return (await apiClient.get(`/challenges/${challengeId}`)).data;
}

async function getChallengeEvent(challengeId) {
  return (await apiClient.get(`/challenge_events/${challengeId}`)).data;
}

async function getChallengeRewards(limit = 50, offset = 0, type = null) {
  return (
    await apiClient.get(
      `/challenge_rewards?limit=${limit}&offset=${offset}${
        type ? `&type=${type}` : ""
      }`
    )
  ).data;
}

async function createChallenge(challenge) {
  return (await apiClient.post(`/challenges`, challenge)).data;
}

async function createChallengeEvent(challengeEvent) {
  return (await apiClient.post(`/challenge_events`, challengeEvent)).data;
}

async function updateChallenge(challenge) {
  return (await apiClient.put(`/challenges/${challenge.id}`, challenge)).data;
}

async function updateChallengeEvent(challengeEvent) {
  return (
    await apiClient.put(
      `/challenge_events/${challengeEvent.id}`,
      challengeEvent
    )
  ).data;
}

async function deleteChallenge(challengeId) {
  return (await apiClient.delete(`/challenges/${challengeId}`)).data;
}

async function deleteChallengeEvent(challengeEventId) {
  return (await apiClient.delete(`/challenge_events/${challengeEventId}`)).data;
}
