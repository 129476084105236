import { createStore } from "vuex";
import auth from "./auth";
import data from "./data";
import ui from "./ui";

export default createStore({
  state: {
    isLoaded: false,
    isSidebarOpen: true,
    isMobile: window.innerWidth < 768,
    viewportHeight: window.innerHeight,
    page: null,
  },

  getters: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    isAndroid() {
      return /android/i.test(navigator.userAgent);
    },

    resourceURL() {
      return process.env.VUE_APP_RESOURCE_URL;
    },
  },

  mutations: {
    LOAD_APP(state) {
      state.isLoaded = true;
    },
    TOGGLE_SIDEBAR(state, payload) {
      state.isSidebarOpen = payload;
    },
    SET_MOBILE_STATE(state, payload) {
      state.isMobile = payload;
    },
    SET_VIEWPORT_HEIGHT(state, payload) {
      state.viewportHeight = payload;
    },
    CHANGE_PAGE(state, payload) {
      state.page = payload;
    },
  },

  actions: {
    init: async ({ commit, dispatch }) => {
      await dispatch("auth/autoLogin");
      await dispatch("data/getGameInfo");
      commit("LOAD_APP");
    },

    toggleSidebar({ commit, state }, toggle = !state.isSidebarOpen) {
      commit("TOGGLE_SIDEBAR", toggle);
    },

    changePage({ state, getters, commit, dispatch }, page) {
      if (
        getters["ui/activeModal"] &&
        getters["ui/activeModal"].canClickOutside
      ) {
        dispatch("ui/closeModal");
      }
      if (state.isMobile) dispatch("toggleSidebar", false);
      document.documentElement.classList.remove("scroll-lock");
      commit("CHANGE_PAGE", page);
    },
  },

  modules: {
    auth,
    data,
    ui,
  },
});
