import apiClient from "@/api";

export const webhookService = {
  getIGDBWebhooks,
  createIGDBWebhook,
  deleteIGDBWebhook,
};

async function getIGDBWebhooks(
  limit,
  offset,
  field = "active",
  order = "asc",
  active
) {
  const isActive = active ? `&active=${active}` : "";

  return await apiClient.get(
    `/igdb/webhooks?limit=${limit}&offset=${offset}&sort_field=${field}&sort_order=${order}${isActive}`
  );
}

async function createIGDBWebhook(category, subcategory) {
  return (
    await apiClient.post(`/igdb/webhooks`, {
      category: category,
      sub_category: subcategory,
    })
  ).data;
}

async function deleteIGDBWebhook(id) {
  return (await apiClient.delete(`/igdb/webhooks/${id}`)).data;
}
