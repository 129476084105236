import { gameService } from "@/services";

export default {
  namespaced: true,

  state: {
    platforms: null,
    dlcCategories: [1, 2, 5, 6, 7, 13, 14],
    remakeCategories: [8, 9, 10, 11, 12],
    mainGameCategories: [0, 3, 4],
    noParentGameCategories: [0, 3],
  },

  getters: {
    platforms(state) {
      return state.platforms.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {}
      );
    },
  },

  mutations: {
    SET_PLATFORMS: (state, data) => {
      state.platforms = data;
    },
  },

  actions: {
    getGameInfo: async ({ commit }) => {
      const platforms = await gameService.getPlatforms();
      commit("SET_PLATFORMS", platforms);
    },
  },
};
