import apiClient from "@/api";

export const historyService = {
  getChangeHistory,
  getChangeHistoryByServiceGame,
  getAdminHistoryStats,
};

async function getChangeHistory(
  limit = 50,
  offset = 0,
  user,
  resource,
  sort = "created_at",
  order = "desc"
) {
  const userId = user ? `&user_id=${user}` : "";
  const resourceId = resource ? `&resource=${resource}` : "";
  return await apiClient.get(
    `/admin_history?limit=${limit}&offset=${offset}&sort_field=${sort}&sort_order=${order}${userId}${resourceId}`
  );
}

async function getChangeHistoryByServiceGame(
  serviceGameId,
  limit = 50,
  offset = 0,
  sort = "created_at",
  order = "desc"
) {
  return await apiClient.get(
    `/admin_history?limit=${limit}&offset=${offset}&sort_field=${sort}&sort_order=${order}&resource_id=${serviceGameId}&resource=manualServiceGame`
  );
}

async function getAdminHistoryStats(date_from, date_to, user_id) {
  return (
    await apiClient.get(`/admin_history/stats`, {
      params: { date_from, date_to, user_id },
    })
  ).data;
}
