import apiClient from "@/api";

export const syncService = {
  resyncPSNGame,
  resyncXboxGame,
  resyncSteamGame,
  resyncGOGGame,
  resyncRetroGame,
  resyncSteamUser,
  resyncPSNUser,
  resyncXboxUser,
  resyncRetroUser,
  resyncGOGUser,
  resyncFFXIVUser,
  resyncCSGOUser,
  resyncOverwatchUser,
  refreshSteamGame,
  refreshRetroGame,
  refreshPSNGame,
};

async function resyncPSNGame(externalId) {
  return (await apiClient.put(`/psn_sync/resync_game/${externalId}`)).data;
}

async function resyncXboxGame(externalId) {
  return (await apiClient.put(`/xbox_sync/resync_game/${externalId}`)).data;
}

async function resyncSteamGame(externalId) {
  return (await apiClient.put(`/steam_sync/resync_game/${externalId}`)).data;
}

async function resyncGOGGame(externalId) {
  return (await apiClient.put(`/gog_sync/resync_game/${externalId}`)).data;
}

async function resyncRetroGame(externalId) {
  return (
    await apiClient.put(`/retro_achievement_sync/resync_game/${externalId}`)
  ).data;
}

async function resyncSteamUser(userId, full = "false") {
  return (
    await apiClient.post(`/linked_accounts/steam/sync/${userId}?full=${full}`)
  ).data;
}

async function resyncPSNUser(userId) {
  return (await apiClient.post(`/linked_accounts/psn/sync/${userId}`)).data;
}

async function resyncXboxUser(userId) {
  return (await apiClient.post(`/linked_accounts/xbox/sync/${userId}`)).data;
}

async function resyncRetroUser(userId) {
  return (
    await apiClient.post(`/linked_accounts/retro_achievement/sync/${userId}`)
  ).data;
}

async function resyncGOGUser(userId) {
  return (await apiClient.post(`/linked_accounts/gog/sync/${userId}`)).data;
}

async function resyncFFXIVUser(userId) {
  return (await apiClient.post(`/linked_accounts/ffxiv/sync/${userId}`)).data;
}

async function resyncCSGOUser(userId) {
  return (await apiClient.post(`/linked_accounts/csgo/sync/${userId}`)).data;
}

async function resyncOverwatchUser(userId) {
  return (await apiClient.post(`/linked_accounts/overwatch/sync/${userId}`))
    .data;
}

async function refreshSteamGame(externalId) {
  return (await apiClient.put(`/steam_sync/refresh_game/${externalId}`)).data;
}

async function refreshRetroGame(externalId) {
  return (
    await apiClient.put(`/retro_achievement_sync/refresh_game/${externalId}`)
  ).data;
}

async function refreshPSNGame(externalId) {
  return (await apiClient.put(`/psn_sync/refresh_game/${externalId}`)).data;
}