import apiClient from "@/api";

export const dataService = {
  getServiceGameIssues,
  getManualServiceGames,
  getServiceGames,
  getServiceGame,
  remapServiceGame,
  remapGame,
  getGameById,
  updateManualServiceGame,
  deleteServiceGameIssue,
  blacklistPSNGame,
  refreshGames,
  validateGame,
  deleteGame,
  getHltbData,
  updateHLTBData
};

async function getServiceGameIssues(
  limit = 50,
  offset = 0,
  sort_order = "desc",
  service,
  issue
) {
  return await apiClient.get(`/service_game_issues`, {
    params: {
      limit,
      offset,
      sort_order,
      created_at: "created_at",
      service,
      issue,
    },
  });
}

async function getManualServiceGames(
  limit = 50,
  offset = 0,
  order = "desc",
  processed = false,
  service,
  search
) {
  return await apiClient.get(`/manual_service_games`, {
    params: {
      limit,
      offset,
      sort_field: "created_at",
      sort_order: order,
      processed,
      service,
      search,
    },
  });
}

async function getServiceGames(
  limit = 50,
  offset = 0,
  service,
  hasAchievements,
  search,
  order = "asc",
  sort = "name"
) {
  const gameService = service ? `&service=${service}` : "";
  const gameAchievements = hasAchievements
    ? `&has_achievements=${hasAchievements}`
    : "";

  const results = await apiClient.get(
    `/manual_service_games?limit=${limit}&offset=${offset}&sort_field=${sort}&sort_order=${order}&search=${search}${gameService}${gameAchievements}`
  );
  return results;
}

async function getServiceGame(id) {
  return (await apiClient.get(`/manual_service_games/${id}/`)).data;
}

async function remapServiceGame(id, newGameId) {
  return (
    await apiClient.post(`/manual_service_games/${id}/move`, {
      game_id: newGameId,
    })
  ).data;
}

async function remapGame(gameId, newGameId, commit) {
  return (
    await apiClient.put(`/games/${gameId}/move`, {
      game_id: newGameId,
      commit: commit,
    })
  ).data;
}

async function getGameById(id, expand = "limited") {
  return (await apiClient.get(`/games/${id}?expand=${expand}`)).data[0];
}

async function updateManualServiceGame(id, data) {
  return (await apiClient.put(`/manual_service_games/${id}`, data)).data;
}

async function deleteServiceGameIssue(id) {
  return await apiClient.delete(`/service_game_issues/${id}`);
}

async function blacklistPSNGame(id) {
  return await apiClient.delete(`/manual_service_games/${id}/blacklist`);
}

async function refreshGames(days) {
  return (await apiClient.put(`/games?days=${days}`),
  {},
  { errorHandle: false }).data;
}

async function validateGame(id) {
  return await apiClient.post(`/service_game_issues/${id}/deletion/validate`);
}

async function deleteGame(id) {
  return await apiClient.delete(`/games/${id}`);
}

async function getHltbData(
  limit = 50,
  offset = 0,
  sort = "comp_main_count",
  order = "desc",
  processed = false,
  search
) {
  return await apiClient.get(`/hltb_data`, {
    params: {
      limit,
      offset,
      sort_field: sort,
      sort_order: order,
      processed,
      search,
    },
  });
}

async function updateHLTBData(id, data) {
  return await apiClient.put(`/hltb_data/${id}`, data);
}
