import apiClient from "@/api";

export const patreonService = {
  getPatrons,
  getPatreonWebhooks,
  updatePatreonWebhook,
  getPledgeTotal,
};

async function getPatrons(limit = 20, offset = 0) {
  return await apiClient.get(
    `/users?roles=backlogMyth,backlogLegend,backlogSlayer&limit=${limit}&offset=${offset}`
  );
}

async function getPatreonWebhooks(limit = 100, offset = 0) {
  return await apiClient.get(
    `/patreon/webhooks?limit=${limit}&offset=${offset}`
  );
}

async function updatePatreonWebhook(id, userId) {
  return await apiClient.put(`/patreon/webhooks/${id}`, { user_id: userId });
}

async function getPledgeTotal(id = 6847415) {
  return (await apiClient.get(`/patreon/campaigns/${id}/pledge_total`)).data;
}
